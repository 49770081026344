@use "../utils" as *;

#canvas {
  position: absolute;
  left: 0;
  height: 800px;
  width: 100%;
}

.hero {
  position: relative;
  height: rem(575);

  &--homepage {
    background-image: linear-gradient(
      145deg,
      var(--blue-900) 45%,
      var(--blue-800) 75%
    );
    background-color: var(--blue-900);
    padding: 0 rem(30);
  }

  &--vervendo {
    background-image: url(../../../img/bg-vervendo.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      360deg,
      rgba(255, 255, 255, 0.8) 55%,
      rgba(211, 225, 255, 0.8) 70%,
      rgba(246, 250, 255, 0.064) 100%
    );
  }

  &__logo {
    position: relative;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__text-container {
    padding-top: rem(120);
    text-shadow: 0 2px 2px rgb(0 0 0 / 30%);
  }

  &__heading-container {
    margin: rem(6) 0;
  }

  &__heading {
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: rem(42);
    line-height: 0.9;
    clip-path: inset(0 0 0 0);
    display: inline-flex;
  }

  .clip-container {
    clip-path: inset(0 0 0 0);
  }

  &__body {
    color: var(--blue-300);
    display: inline-block;
  }

  &__image-container {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    transform-origin: right top;
    transform: scale(1.5) translateY(rem(-90));
    transition: transform 200ms ease-in;

    @include breakpoint-down(sm) {
      transform: scale(1.3) translateY(rem(-30));
    }

    @include breakpoint-down(xsm) {
      transform: scale(0.8) translateY(0);
    }
  }

  &__image {
    width: 100%;
  }
}

html {
  font-size: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
}

*, *::after, *::before {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
  background-color: var(--blue-900);
}

:root {
  --blue-900: hsl(209, 100%, 14%);
  --blue-800: hsl(211, 87%, 21%);
  --blue-700: hsl(211, 76%, 29%);
  --blue-600: hsl(211, 54%, 38%);
  --blue-500: hsl(210, 42%, 49%);
  --blue-400: hsl(211, 50%, 60%);
  --blue-300: hsl(211, 53%, 75%);
  --blue-200: hsl(210, 39%, 84%);
  --blue-100: hsl(208, 23%, 89%);
  --yellow-900: hsl(36, 100%, 20%);
  --yellow-800: hsl(36, 100%, 30%);
  --yellow-700: hsl(36, 97%, 40%);
  --yellow-600: hsl(36, 87%, 50%);
  --yellow-500: hsl(36, 77%, 60%);
  --yellow-400: hsl(36, 78%, 70%);
  --yellow-300: hsl(36, 100%, 79%);
  --yellow-200: hsl(35, 100%, 89%);
  --yellow-100: hsl(35, 100%, 94%);
}

:root {
  --font-primary: "proxima-nova", "Trebuchet MS", sans-serif;
}

.grid {
  display: grid;
}

.grid-col__2fr-1fr {
  grid-template-columns: 2fr 1fr;
}
.grid-col__3fr-1fr {
  grid-template-columns: 3fr 1fr;
}

.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-inline {
  display: inline-flex;
}

.flow-wrap {
  flex-flow: wrap;
}

.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}
.jc-c {
  justify-content: center;
}
.jc-start {
  justify-content: flex-start;
}
.jc-end {
  justify-content: flex-end;
}

.ai-start {
  align-items: flex-start;
}
.ai-end {
  align-items: flex-end;
}
.ai-c {
  align-items: center;
}
.ai-base {
  align-items: baseline;
}

.gap-01 {
  gap: 0.1rem;
}
.gap-02 {
  gap: 0.2rem;
}
.gap-03 {
  gap: 0.3rem;
}
.gap-05 {
  gap: 0.5rem;
}
.gap-06 {
  gap: 0.6rem;
}
.gap-07 {
  gap: 0.7rem;
}
.gap-08 {
  gap: 0.8rem;
}
.gap-09 {
  gap: 0.9rem;
}
.gap-1 {
  gap: 1rem;
}
.gap-15 {
  gap: 1.5rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-3 {
  gap: 3rem;
}
.gap-4 {
  gap: 4rem;
}
.gap-5 {
  gap: 5rem;
}

.w-100 {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

p {
  margin: 0;
}

.--bold {
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4375rem;
  padding: 0.4375rem 0.875rem;
  border-radius: 0.3125rem;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.00625rem;
  transition: all 100ms ease-out;
}
@media (max-width: 377px) {
  .button {
    font-size: 0.8rem;
    padding: 0.4375rem 0.4375rem;
    letter-spacing: 0.01875rem;
  }
}
.button--yellow {
  background-color: var(--yellow-400);
  color: var(--blue-900);
  box-shadow: 0 2px 1px var(--yellow-700);
}
.button--yellow:hover, .button--yellow:active {
  background-color: var(--yellow-500);
}
.button--yellow:active {
  transform: translateY(1px);
  box-shadow: none;
}
.button--transparent {
  background-color: transparent;
  color: var(--yellow-400);
  border: 1px solid;
  font-weight: 400;
}
.button--transparent:hover {
  background-color: var(--blue-700);
}
.button--blue {
  background-color: var(--blue-500);
  color: var(--blue-100);
  box-shadow: 0 2px 1px var(--blue-700);
}
.button--blue:hover, .button--blue:active {
  background-color: var(--blue-400);
}
.button--blue:active {
  transform: translateY(1px);
  box-shadow: none;
}

/**-------------------**
| SECTION STYLING
**--------------------**/
.section__heading {
  color: #fff;
  text-align: center;
  letter-spacing: 0.3125rem;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 100;
  margin-bottom: 2.5rem;
}

/**-------------------**
| ABOUT CARD STYLING
**--------------------**/
.about {
  position: relative;
  z-index: 99;
  padding: 1.875rem;
  max-width: 52ch;
  margin: -3.75rem 1.875rem 0;
  background: linear-gradient(180deg, var(--blue-300) 0%, var(--blue-400) 100%);
  box-shadow: 0px 12px 24px rgba(0, 19, 36, 0.25);
  border-radius: 0.625rem;
}
@media (max-width: 377px) {
  .about {
    margin: -3.75rem 1.875rem 0;
  }
}
.about__heading {
  font-size: 1.125rem;
  color: var(--blue-900);
  margin-bottom: -0.3125rem;
}
.about__subheading {
  font-size: 0.5625rem;
  color: var(--blue-500);
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
}
.about__body {
  font-size: 0.875rem;
  color: var(--blue-900);
  line-height: 1.4;
}
.about__job {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background-color: var(--blue-100);
  padding: 0.75rem 0.875rem 0.875rem 0.875rem;
  text-align: center;
  border: 1px solid var(--blue-500);
  border-radius: 0.625rem;
}
.about__list-title {
  letter-spacing: 0.0125rem;
  font-weight: 600;
  font-size: 0.875rem;
}
.about__list {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0;
}
.about__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8125rem;
  list-style-type: none;
  text-align: center;
  line-height: 1.2;
}
@media (max-width: 349px) {
  .about__list-item {
    font-size: 0.75rem;
  }
}
.about__list-item img {
  height: 4rem;
}
@media (max-width: 349px) {
  .about__list-item img {
    height: 2rem;
  }
}

/**-------------------**
| HOMEPAGE PROJECT CARD STYLING
**--------------------**/
.project__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  max-width: 52ch;
  padding: 3.75rem 1.875rem 1.875rem;
  margin: 3.75rem 1.875rem 0;
  color: var(--blue-100);
  border-radius: 0.9375rem;
  background-color: var(--blue-800);
  box-shadow: 0px 12px 24px rgba(0, 19, 36, 0.25);
}
.project__header {
  align-items: center;
  display: flex;
  position: absolute;
  gap: 0.5rem;
  top: -2.8125rem;
  left: 50%;
  width: fit-content;
  height: 45px;
  padding: 0 1rem;
  transform: translateX(-50%);
  background-color: var(--yellow-400);
  border-radius: 0.625rem 0.625rem 0 0;
}
.project .header__logo {
  display: grid;
  place-items: center;
  height: 80px;
  width: 80px;
  background-color: var(--blue-900);
  border: 5px solid var(--yellow-400);
  border-radius: 100%;
}
.project .header__tag {
  color: var(--blue-900);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.01875rem;
}
.project__name {
  display: inline-flex;
  margin-right: 0.3125rem;
  margin-bottom: 0.9375rem;
  font-size: 1.5rem;
}
.project__date {
  display: inline-flex;
  position: relative;
  bottom: 3px;
  font-size: 0.75rem;
  border: 1px solid;
  color: var(--yellow-400);
  padding: 0.1875rem 0.625rem;
  margin-bottom: 0.5rem;
  border-radius: 100vh;
}
.project__body {
  color: var(--blue-200);
  font-weight: 300;
  letter-spacing: 0.01875rem;
  line-height: 1.35;
}
.project__roles {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.project__skills {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.project .tag__list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3125rem;
}
.project .tag {
  border-radius: 100vh;
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.0375rem;
  color: var(--blue-300);
  cursor: default;
}
.project .tag__role {
  background-color: var(--blue-700);
  padding: 0.1875rem 0.75rem;
}
.project .tag__role:hover {
  background-color: var(--blue-600);
}
.project .tag__skill {
  background-color: transparent;
  padding: 0.375rem 0.75rem 0.375rem 2rem;
  border: 1px solid var(--blue-700);
}
.project .tag__skill:hover {
  background-color: var(--blue-700);
}
.project__links-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.project__links {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.project__links .button {
  width: 100%;
}

/* the first project needs spacing to not hide the heading */
[data-project=vervendo] {
  margin-top: 5.625rem;
}

.contact {
  padding: 1rem;
  background-image: linear-gradient(180deg, var(--yellow-400) 0%, var(--yellow-300) 100%);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  max-width: 52ch;
  padding: 1.875rem;
  margin: 3.75rem 1.875rem 0;
  color: var(--blue-900);
  border-radius: 0.9375rem;
  box-shadow: 0px 12px 24px rgba(0, 19, 36, 0.25);
}
.contact__name {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}
.contact__body {
  line-height: 1.4;
  text-align: left;
}

/**-------------------**
| SINGLE COMPANY PAGE
**--------------------**/
.roles {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  max-width: 52ch;
  padding: 1.875rem;
  margin: 3.75rem 1.875rem 0;
  border-radius: 0.9375rem;
  background-color: var(--blue-800);
  box-shadow: 0px 12px 24px rgba(0, 19, 36, 0.25);
}
.roles__body {
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.3;
}
.roles__primary {
  background-color: #fff;
  color: var(--blue-800);
  margin: -1.875rem -1.875rem 0;
  padding: 1.875rem;
  border-radius: 0.625rem;
}
.roles__primary .roles__body {
  color: var(--blue-600);
  font-weight: 300;
}
.roles__secondary {
  color: var(--blue-100);
}
.roles__secondary .roles__body {
  color: var(--blue-300);
}

.card {
  position: relative;
  max-width: 52ch;
  padding: 1.875rem;
  margin: 3.75rem 1.875rem 0;
  border-radius: 0.9375rem;
  color: var(--blue-700);
  background-color: var(--blue-100);
  box-shadow: 0px 12px 24px rgba(0, 19, 36, 0.25);
}
.card__font-svg {
  width: 5rem;
}
.card__body {
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.3;
}

.grid {
  display: inline-grid;
}
.grid__color-usage {
  grid-template-columns: 5fr 3fr 1fr 0.7fr 0.3fr;
}
.grid__color-value {
  grid-template-columns: 1fr 4fr 4fr;
}
.grid__line {
  width: 0.3125rem;
}
.grid__hex {
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 3.9375rem;
  z-index: 99999;
  background-color: rgba(7, 52, 100, 0.95);
  padding: 0.9375rem 1.875rem;
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.2);
  z-index: 99999;
}
@media (max-width: 377px) {
  .navbar {
    padding: 0.9375rem 0.9375rem;
  }
}
.navbar__logo-container {
  display: inline-flex;
}
@media (max-width: 585px) {
  .navbar__logo {
    max-width: 150px;
  }
}
@media (max-width: 349px) {
  .navbar__logo {
    display: none;
  }
}
.navbar__logo--mobile {
  display: none;
}
@media (max-width: 349px) {
  .navbar__logo--mobile {
    display: block;
  }
}

#canvas {
  position: absolute;
  left: 0;
  height: 800px;
  width: 100%;
}

.hero {
  position: relative;
  height: 35.9375rem;
}
.hero--homepage {
  background-image: linear-gradient(145deg, var(--blue-900) 45%, var(--blue-800) 75%);
  background-color: var(--blue-900);
  padding: 0 1.875rem;
}
.hero--vervendo {
  background-image: url(../../../img/bg-vervendo.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
.hero__overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.8) 55%, rgba(211, 225, 255, 0.8) 70%, rgba(246, 250, 255, 0.064) 100%);
}
.hero__logo {
  position: relative;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero__text-container {
  padding-top: 7.5rem;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}
.hero__heading-container {
  margin: 0.375rem 0;
}
.hero__heading {
  margin: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.625rem;
  line-height: 0.9;
  clip-path: inset(0 0 0 0);
  display: inline-flex;
}
.hero .clip-container {
  clip-path: inset(0 0 0 0);
}
.hero__body {
  color: var(--blue-300);
  display: inline-block;
}
.hero__image-container {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  transform-origin: right top;
  transform: scale(1.5) translateY(-5.625rem);
  transition: transform 200ms ease-in;
}
@media (max-width: 585px) {
  .hero__image-container {
    transform: scale(1.3) translateY(-1.875rem);
  }
}
@media (max-width: 377px) {
  .hero__image-container {
    transform: scale(0.8) translateY(0);
  }
}
.hero__image {
  width: 100%;
}

.divider {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.divider--small {
  height: 1px;
  background-color: var(--blue-700);
}
.divider--medium {
  padding: 3.75rem 0;
}

/**-------------------**
| ADDS TAG ICONS
**--------------------**/
*[data-tag]::before {
  content: "";
  height: 14px;
  width: 14px;
  position: absolute;
  background-repeat: no-repeat;
  transform: translateX(-19px);
}

[data-tag=html]::before {
  background-image: url(../../../img/icons/icon-html.svg);
}

[data-tag=css]::before {
  background-image: url(../../../img/icons/icon-css.svg);
}

[data-tag=scss]::before {
  background-image: url(../../../img/icons/icon-scss.svg);
}

[data-tag=ng]::before {
  background-image: url(../../../img/icons/icon-ng.svg);
}

[data-tag=ts]::before {
  background-image: url(../../../img/icons/icon-ts.svg);
}

[data-tag=ps]::before {
  background-image: url(../../../img/icons/icon-ps.svg);
}

[data-tag=ae]::before {
  background-image: url(../../../img/icons/icon-ae.svg);
}

[data-tag=ai]::before {
  background-image: url(../../../img/icons/icon-ai.svg);
}

[data-tag=pr]::before {
  background-image: url(../../../img/icons/icon-pr.svg);
}

[data-tag=id]::before {
  background-image: url(../../../img/icons/icon-id.svg);
}

[data-tag=figma]::before {
  background-image: url(../../../img/icons/icon-figma.svg);
}

[data-tag=gsap]::before {
  background-image: url(../../../img/icons/icon-gsap.svg);
}

[data-tag=vscode]::before {
  background-image: url(../../../img/icons/icon-vscode.svg);
}

[data-tag=joomla]::before {
  background-image: url(../../../img/icons/icon-joomla.svg);
}

[data-tag=xd]::before {
  background-image: url(../../../img/icons/icon-xd.svg);
}

[data-tag=js]::before {
  background-image: url(../../../img/icons/icon-js.svg);
}

[data-tag=git]::before {
  background-image: url(../../../img/icons/icon-git.svg);
}

[data-tag=github]::before {
  background-image: url(../../../img/icons/icon-github.svg);
}

.palette__color {
  border-radius: 50%;
  position: relative;
}
.palette__line {
  height: 10px;
}
.palette--small {
  width: 2rem;
  height: 2rem;
}
.palette--big {
  width: 4.75rem;
  height: 4.75rem;
  box-shadow: 0px 12px 24px rgba(0, 19, 36, 0.25);
}
.palette--big:not(:last-child) {
  margin-right: -1.25rem;
}
.palette--vervendo-white {
  background-color: #ffffff;
  z-index: 5;
}
.palette--vervendo-blue {
  background-color: #2c6da9;
  z-index: 4;
}
.palette--vervendo-pink {
  background-color: #ff577f;
  z-index: 3;
}
.palette--vervendo-yellow {
  background-color: #ffca41;
  z-index: 2;
}
.palette--vervendo-gold {
  background-color: #c98f1d;
  z-index: 1;
}
.palette--small + .palette--vervendo-pink::after, .palette--small + .palette--vervendo-yellow::after, .palette--small + .palette--vervendo-gold::after {
  content: "new";
  position: absolute;
  right: -0.875rem;
  top: 0.375rem;
  border: 0.1875rem solid var(--blue-100);
  background-color: #fff;
  color: var(--blue-800);
  font-size: 0.5625rem;
  font-weight: 600;
  border-radius: 100vh;
  padding: 0.0625rem 0.25rem;
}

.color-value {
  color: #fff;
  padding: 1.875rem;
  max-width: 52ch;
}
.color-value__line {
  width: 0.625rem;
}
.color-value__hex {
  font-size: 0.875rem;
}
.color-value__heading {
  font-size: 0.6875rem;
  color: var(--blue-400);
}
.color-value__body {
  font-size: 0.6875rem;
  font-weight: 200;
  color: var(--blue-200);
  letter-spacing: 0.01875rem;
  line-height: 1.3;
}

.mockup {
  width: 52ch;
}
@media (max-width: 585px) {
  .mockup {
    width: 350px;
  }
}
@media (max-width: 377px) {
  .mockup {
    width: 300px;
  }
}
.footer__social {
  padding: 5.625rem 0 1.875rem;
}
.footer__credit {
  padding-bottom: 1.875rem;
  font-size: 0.7rem;
  color: var(--blue-200);
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.03125rem;
}
.footer .--yellow-text {
  color: var(--yellow-500);
}
@use "../utils/" as *;

.palette {
  &__color {
    border-radius: 50%;
    position: relative;
  }

  &__line {
    height: 10px;
  }

  &--small {
    width: rem(32);
    height: rem(32);
  }

  &--big {
    width: rem(76);
    height: rem(76);
    box-shadow: 0px 12px 24px rgba(0, 19, 36, 0.25);
  }

  &--big:not(:last-child) {
    margin-right: rem(-20);
  }

  &--vervendo-white {
    background-color: #ffffff;
    z-index: 5;
  }

  &--vervendo-blue {
    background-color: #2c6da9;
    z-index: 4;
  }

  &--vervendo-pink {
    background-color: #ff577f;
    z-index: 3;
  }

  &--vervendo-yellow {
    background-color: #ffca41;
    z-index: 2;
  }

  &--vervendo-gold {
    background-color: #c98f1d;
    z-index: 1;
  }

  &--small + &--vervendo-pink::after,
  &--small + &--vervendo-yellow::after,
  &--small + &--vervendo-gold::after {
    content: "new";
    position: absolute;
    right: rem(-14);
    top: rem(6);
    border: rem(3) solid var(--blue-100);
    background-color: #fff;
    color: var(--blue-800);
    font-size: rem(9);
    font-weight: 600;
    border-radius: 100vh;
    padding: rem(1) rem(4);
  }
}

.color-value {
  color: #fff;
  padding: rem(30);
  max-width: 52ch;

  &__line {
    width: rem(10);
  }

  &__hex {
    font-size: rem(14);
  }

  &__heading {
    font-size: rem(11);
    color: var(--blue-400);
  }

  &__body {
    font-size: rem(11);
    font-weight: 200;
    color: var(--blue-200);
    letter-spacing: rem(0.3);
    line-height: 1.3;
  }
}

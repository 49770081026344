:root {
  --blue-900: hsl(209, 100%, 14%);
  --blue-800: hsl(211, 87%, 21%);
  --blue-700: hsl(211, 76%, 29%);
  --blue-600: hsl(211, 54%, 38%);
  --blue-500: hsl(210, 42%, 49%);
  --blue-400: hsl(211, 50%, 60%);
  --blue-300: hsl(211, 53%, 75%);
  --blue-200: hsl(210, 39%, 84%);
  --blue-100: hsl(208, 23%, 89%);

  --yellow-900: hsl(36, 100%, 20%);
  --yellow-800: hsl(36, 100%, 30%);
  --yellow-700: hsl(36, 97%, 40%);
  --yellow-600: hsl(36, 87%, 50%);
  --yellow-500: hsl(36, 77%, 60%);
  --yellow-400: hsl(36, 78%, 70%);
  --yellow-300: hsl(36, 100%, 79%);
  --yellow-200: hsl(35, 100%, 89%);
  --yellow-100: hsl(35, 100%, 94%);
}

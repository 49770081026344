@use "../utils/" as *;

.footer {
  &__social {
    padding: rem(90) 0 rem(30);
  }
  
  &__credit {
    padding-bottom: rem(30);
    font-size: 0.7rem;
    color: var(--blue-200);
    text-align: center;
    font-weight: 300;
    letter-spacing: rem(.5);
  }

  .--yellow-text {
    color: var(--yellow-500);
  }
}

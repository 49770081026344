h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

p {
  margin: 0;
}

.--bold {
  font-weight: 600;
}
@use "../utils" as *;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(7);
  padding: rem(7) rem(14);
  border-radius: rem(5);
  font-size: rem(13);
  font-weight: 600;
  letter-spacing: rem(0.1);
  transition: all 100ms ease-out;

  @include breakpoint-down(xsm) {
    font-size: 0.8rem;
    padding: rem(7) rem(7);
    letter-spacing: rem(0.3);
  }

  &--yellow {
    background-color: var(--yellow-400);
    color: var(--blue-900);
    box-shadow: 0 2px 1px var(--yellow-700);

    &:hover,
    &:active {
      background-color: var(--yellow-500);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: none;
    }
  }

  &--transparent {
    background-color: transparent;
    color: var(--yellow-400);
    border: 1px solid;
    font-weight: 400;

    &:hover {
      background-color: var(--blue-700);
    }
  }

  &--blue {
    background-color: var(--blue-500);
    color: var(--blue-100);
    box-shadow: 0 2px 1px var(--blue-700);

    &:hover,
    &:active {
      background-color: var(--blue-400);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: none;
    }
  }
}
